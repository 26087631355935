import React from "react"
import { graphql } from "gatsby"
import ProductPage from '../../components/gallery/productPage'
import Seo from "../../components/SEO"


const Color = ({data}) => {

    const _title = "Larix Medium Color Lamp";

    const _quotes = [
        `A longing sun, eager for touch that is not there.`,
        `The Sun's body is formed with delicate lines and clear lighting. Its head is enlightened and 
        the glowing leaves us gazing in wonder. There is a visible contrast between the upper part 
        and the round dome protected by discrete, red light. The lamp evokes a path leading into 
        the light and gives us discrete, but not so ordinary, aesthetic elegance.`,
        `Floating on a surface, I was drinking a shade of the sea, which was flaring brightly.`
    ]


    const _properties = {
      "Height": "83 cm",
      "Width": "22 cm",
      "Weight": "3 kg"
    }


    const _walloftext = {
        "Product's purpose": [
            `
                The lamp is meant primarily for ambient lighting. It decorates the space with 
                mild lighting and rich design. The Larix Medium Color Lamp gives the room feelings 
                of brightness, light and purity.
            `
         ],
        "Materials": [
            `
                The lamp is made entirely of natural materials. 
                The wooden parts are made of solid beech wood, coated with 
                water-soluble acrylics with natural pigments and decorated 
                with oil paints and a pure gold coating.
                The shade is made of high quality silk, embroidered with 
                colorful decorative shapes.
            `
        ],
        "Production": [
            `
                Wooden parts are machine painted and patinated. The painter then hand-paints
                decorative elements with oil paints. The production of the lamp is completed by 
                the restorer by hand application of gilding paste - a paste with fine particles 
                of pure gold. 
            `,
            `
                The base for the shade is high quality silk, to which decorative patterns
                and shapes are machine-embroidered. The master of embroidery has developed the 
                technology to such an extent that needle stitches imitate hand embroidery, 
                which is also evident when looking closely at the embroidery.
            `,
            `
                Lastly, the master stretches the silk over the metal frame 
                and sews upholstery strips to the edges.
            `
        ],
    }


    return (
      <>
        <Seo title={_title} />
        <ProductPage 
          galleryItems={data.allFile}
          title={_title}
          quotes={_quotes}
          properties={_properties}
          walloftext={_walloftext}
          spinnerSprite={{on: data.on, off: data.off}}
        />
      </>
    )
}

export default Color;


export const query = graphql`
query {
    allFile(filter: {relativeDirectory: {eq: "color"}} sort: {fields: name}) {
        nodes {
            childImageSharp {
                original {
                    src
                    height
                    width
                }
                webp: resize(width: 334, toFormat: WEBP, pngQuality: 50) {
                    src
                    height
                    width
                }
                png: resize(width: 334, toFormat: PNG, pngQuality: 50) {
                    src
                }
                thumbnail: resize(height: 150, toFormat: WEBP, webpQuality: 80) {
                    src
                    height
                    width
                }
            }
            name
        }
    }
    on: allFile(filter: {relativeDirectory: {eq: "spin/color_on"}} sort: {fields: name}) {
        nodes {
            childImageSharp {
                webp: resize(height: 700, toFormat: WEBP, webpQuality: 90) {
                    src
                    width
                    height
                }
            }
        }
    }
    off: allFile(filter: {relativeDirectory: {eq: "spin/color_off"}} sort: {fields: name}) {
        nodes {
            childImageSharp {
                webp: resize(height: 700, toFormat: WEBP, webpQuality: 90) {
                    src
                    width
                    height
                }
            }
        }
    }
}`

